// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Theme$GlazedWebsite from "../Theme.js";
import * as Layout$GlazedWebsite from "../Layout.js";
import * as Heading$GlazedWebsite from "../Heading.js";
import * as PageLayout$GlazedWebsite from "../PageLayout.js";

var content = Curry._1(Css.style, /* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.padding4(Css.rem(4.1875), Css.rem(1.25), Css.rem(8.1875), Css.rem(1.25)),
        /* :: */[
          Css.display(/* flex */-1010954439),
          /* :: */[
            Css.flexDirection(/* column */-963948842),
            /* :: */[
              Css.justifyContent(/* flexEnd */924268066),
              /* :: */[
                Css.flex3(1, 0, /* auto */-1065951377),
                /* :: */[
                  Css.backgroundColor(Css.hex(Theme$GlazedWebsite.Colors.almostAlmostWhite)),
                  /* :: */[
                    Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                          Css.justifyContent(/* flexStart */662439529),
                          /* :: */[
                            Css.padding4(Css.rem(12.25), Css.rem(2.5), Css.rem(6.25), Css.rem(4.75)),
                            /* [] */0
                          ]
                        ]),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var heading = Curry._1(Css.style, /* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.padding4(/* `rem */[
              5691738,
              1.875
            ], /* zero */-789508312, /* `rem */[
              5691738,
              5.625
            ], /* `rem */[
              5691738,
              0.9375
            ]),
        /* :: */[
          Css.fontSize(/* `rem */[
                5691738,
                4.125
              ]),
          /* :: */[
            Css.color(Css.hex(Theme$GlazedWebsite.Colors.glazedBabyBlueText)),
            /* :: */[
              Css.before(/* :: */[
                    Css.contentRule(/* `text */[
                          -856044371,
                          ""
                        ]),
                    /* :: */[
                      Css.display(/* block */888960333),
                      /* :: */[
                        Css.position(/* absolute */-1013592457),
                        /* :: */[
                          Css.top(/* `px */[
                                25096,
                                0
                              ]),
                          /* :: */[
                            Css.left(/* `px */[
                                  25096,
                                  0
                                ]),
                            /* :: */[
                              Css.width(Css.px(130)),
                              /* :: */[
                                Css.height(Css.px(130)),
                                /* :: */[
                                  Css.border(Css.px(1), /* solid */12956715, Css.hex(Theme$GlazedWebsite.Colors.glazedBabyBlueDarker)),
                                  /* :: */[
                                    Css.opacity(0.1),
                                    /* :: */[
                                      Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                                            Css.width(/* `px */[
                                                  25096,
                                                  258
                                                ]),
                                            /* :: */[
                                              Css.height(/* `px */[
                                                    25096,
                                                    258
                                                  ]),
                                              /* [] */0
                                            ]
                                          ]),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]),
              /* :: */[
                Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                      Css.padding4(/* `rem */[
                            5691738,
                            5.625
                          ], /* zero */-789508312, /* `rem */[
                            5691738,
                            6.875
                          ], /* `rem */[
                            5691738,
                            0.9375
                          ]),
                      /* :: */[
                        Css.width(/* auto */-1065951377),
                        /* :: */[
                          Css.fontSize(Css.rem(9)),
                          /* [] */0
                        ]
                      ]
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var descriptions = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.padding4(/* zero */-789508312, Css.rem(3.125), /* zero */-789508312, Css.rem(0.9375)),
          /* :: */[
            Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                  Css.maxWidth(Css.rem(37.5)),
                  /* [] */0
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var shortDescription = Curry._1(Css.style, /* :: */[
      Css.lineHeight(/* `abs */[
            4845682,
            2
          ]),
      /* :: */[
        Css.fontSize(Css.rem(0.875)),
        /* :: */[
          Css.fontFamily(/* `custom */[
                1066567601,
                Theme$GlazedWebsite.Fonts.text
              ]),
          /* :: */[
            Css.color(Css.hex(Theme$GlazedWebsite.Colors.grey)),
            /* :: */[
              Css.paddingBottom(Css.rem(0.625)),
              /* :: */[
                Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                      Css.order(2),
                      /* :: */[
                        Css.flex3(1, 0, /* auto */-1065951377),
                        /* :: */[
                          Css.display(/* flex */-1010954439),
                          /* :: */[
                            Css.alignItems(/* center */98248149),
                            /* :: */[
                              Css.justifyContent(/* flexEnd */924268066),
                              /* :: */[
                                Css.paddingLeft(Css.rem(6.25)),
                                /* :: */[
                                  Css.fontSize(Css.rem(2)),
                                  /* :: */[
                                    Css.lineHeight(/* `abs */[
                                          4845682,
                                          1.5
                                        ]),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  content: content,
  heading: heading,
  descriptions: descriptions,
  shortDescription: shortDescription
};

function NotFound(Props) {
  return React.createElement(Layout$GlazedWebsite.make, {
              children: React.createElement(PageLayout$GlazedWebsite.make, {
                    children: null,
                    className: content,
                    useDarkNavBarLinks: true
                  }, React.createElement(Heading$GlazedWebsite.make, {
                        level: /* H1 */0,
                        className: heading,
                        children: "404"
                      }), React.createElement("div", {
                        className: descriptions
                      }, React.createElement(Heading$GlazedWebsite.make, {
                            level: /* H3 */2,
                            className: shortDescription,
                            children: "You just hit a route that doesn\'t exist... The sadness."
                          })))
            });
}

var make = NotFound;

var $$default = NotFound;

export {
  Styles ,
  make ,
  $$default ,
  $$default as default,
  
}
/* content Not a pure module */
